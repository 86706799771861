import { Calendar, Event, EventLevel, day } from './helpers.ts';
import internationalEvents from '../../locale/_/calendar/events.ts';
import localEvents from '../../data/calendar/events.ts';

import rawNamedays from '../../data/names/namedays.json' with { type: 'json' };

const _namedays = Object.entries(rawNamedays as Record<string, string[]>)
    .flatMap(([name, namedays]) => {
        return namedays.map((nameday) => {
            const [m, d] = nameday.split('-');
            return new Event(`nameday$${name}`, null, parseInt(m), day(parseInt(d)), EventLevel.Nameday);
        });
    });

export const buildCalendar = (baseUrl: string) => {
    return new Calendar(
        [...internationalEvents, ...localEvents], // TODO , ...namedays
        baseUrl,
        2021,
        2024,
    );
};
